import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Switch
} from '@material-ui/core';
import "../css/Modal.css";
import DropDown from './DropDown';
import "../css/DropDown.css";
import drop from "../assets/drop.png";
import { TiDelete } from "react-icons/ti";
import { withRouter } from './withRouter';
import { connect } from 'react-redux';
import action from '../store/action';
import { toast } from 'react-toastify';
import DynamicNotificationsForm from './DynamicNotificationsForm';

const EditUserModal = ({ open, onClick, onUpdate, data }) => {

    const [appName, setAppName] = useState("")
    const [image, setImage] = useState("")
    const [appId, setAppId] = useState("")
    const [file, setFile] = useState("")
    const [screenEnable, setScreenEnable] = useState(false)

    const imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result);
            }
        }
        setFile(e.target.files[0])
        reader.readAsDataURL(e.target.files[0])
    }

    useEffect(() => {
        if (data) {
            setAppName(data?.appName)
            setAppId(data?.appId)
            setScreenEnable(data?.futureTrading)
            setImage(data?.image)
        }
    }, [data])
    const removeImage = () => {
        setImage(null)
        setFile(null)

    }

    const save = () => {
        if (!appName) {
            toast.warn('Enter App name')
        } else if (!appId) {
            toast.warn('Enter App id')
        } else if (!image) {
            toast.warn('Select Image')
        }
        else {
            let body = {
                appName, appId, futureTrading: screenEnable, image, file
            }
            onUpdate(body)
            setAppId("")
            setAppName("")
            setImage("")
            setFile("")
            onClick()
        }
    }

    const onCancel = () => {
        setAppId("")
        setAppName("")
        setImage("")
        setFile("")
        onClick()
    }

    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <div className='close-btn' onClick={onClick} style={{ cursor: "pointer", }}><TiDelete /></div>
            <DialogTitle id="dialog-title">
                <h2>{"Create new app"}</h2>
                <div className='line'></div>
            </DialogTitle>
            <DialogContent>
                <div className="modal-main" >
                    <label htmlFor='appName'>{"App Name"}</label>
                    <input type="text" id='appName' value={appName} onChange={(e) => setAppName(e.target.value)} placeholder={"App Name"} />
                    <label htmlFor='appName'>{"App Id"}</label>
                    <input type="text" id='appId' value={appId} onChange={(e) => setAppId(e.target.value)} placeholder={"App Id"} />
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                        <label >{"Enable screen"}</label>
                        <Switch checked={screenEnable} onClick={() => setScreenEnable(!screenEnable)} />
                    </div>
                    {/* <label htmlFor='appName'>{lang === "en" ? "Change Password" : "Changer le mot de passe"}</label>
                    <input type="password" id='password' value={password} onChange={(e) => getPassword(e.target.value)} placeholder={lang === "en" ? "Change Password" : "Changer le mot de passe"} />
                    <label htmlFor='account-type'>{lang === "en" ? "Contact" : "Contact"}</label>
                    <input disabled={true} type="text" id='Contact Number' value={phone} onChange={(e) => getPhone(e.target.value)} placeholder={lang === "en" ? "Contact" : "Contact"} /> */}
                </div>

                <div className="modal-main" >
                    <label htmlFor='Category'>{"Cover Image"}</label>
                    <div className='img-area-1'>
                        <div className='photo1'>
                            <img src={image ? image : drop} alt="" opa={image ? 0 : 1} className="opacit" />
                            <div className='show-img'>
                                <img src={image} alt="" opa={image ? 1 : 0} className='opacit' />
                                <div className='pic-del opacit' opa={image ? 1 : 0} onClick={removeImage} ><TiDelete /></div>
                            </div>
                            <p className='drag opacit' opa={image ? 0 : 1}>{"Drap Photos here"}</p>
                        </div>
                        <div className='img-src-btn1'>
                            <label htmlFor="files">{"Select From Computer"}</label>
                            <input type="file" id="files" accept='image/*' onChange={imageHandler} />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <div className='but-flex'>
                <div className='cancel'>
                    <button onClick={onCancel}>{"Cancel"}</button>
                </div>
                <div className='save-changes'>
                    <button onClick={() => save()}>{"Save"}</button>
                </div>
            </div>
        </Dialog>
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(EditUserModal));

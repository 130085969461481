import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
} from '@material-ui/core';
import "../css/NewModal.css";
import { TiDelete } from "react-icons/ti";
import drop from "../assets/drop.png";
import DropDown from './DropDown';
import { getImageURL } from '../config/uploadmageFunction'
import { edituser, signup } from '../Path/Path';
import { toast } from 'react-toastify';
import { withRouter } from './withRouter';
import { connect } from 'react-redux';
import action from '../store/action';
import dummy from "../assets/pic.jpg"

const ProfileVisibleModal = ({ user, open, onClick, onUpdate, getImage, getUserName, userName, email, image, lang, getid, getFile }) => {
    const [myimg, setMyimg] = useState("");
    const [show, setShow] = useState(false);
   

    // const imageHandler = (e) => {
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         if (reader.readyState === 2) {
    //             getImage(reader.result);
    //             setMyimg(reader.result)
    //         }
    //     }
    //     console.log(e.target.files[0], "e.target.files[0]e.target.files[0]")
    //     setShow(true);
    //     getImage(e.target.files[0])
    //     reader.readAsDataURL(e.target.files[0])
    // }
    const imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                getImage(reader.result);
            }
        }
        setShow(true);
        getFile(e.target.files[0])
        reader.readAsDataURL(e.target.files[0])
    }



    const removeImage = () => {
        getImage(null)
        getFile(null)
        setShow(false)
    }



    useEffect(() => {
    }, [])
    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open}>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "15px" }}>


                <h2 className='h2-head'>{lang === "en" ? "View Profile" : "Se Profil"}</h2>


                <div className='close-btn' onClick={onClick}><TiDelete /></div>
            </div>
            <div className='line'></div>
            <DialogContent>

                <div className="modal-main" >

                    <div className='input-flex'>

                        <div className='flex-input' style={{ width: "100%" }}>
                            <label htmlFor='Date'>{lang === "en" ? "Profile Name" : "Profil navn"}</label>
                            <input type="text" value={userName} id='id' onChange={(e) => getUserName(e.target.value)} />
                        </div>
                        {/* <div className='flex-input' style={{ width: "48%" }}>
                            <label htmlFor='Tagline'>{lang === "en" ? "Type" : "Type"}</label>
                            <input type="text" id='Tagline' placeholder='select type' />
                            <DropDown
                                options={[
                                    { label: 'Select Option', value: 'Select Option' },
                                    { label: 'store', value: 'store' },
                                    { label: 'guard', value: 'guard' },

                                ]}
                                onChange={e => getUserType(e.target.value)}
                                value={typeUser}
                            />

                        </div> */}


                        {/* <div className='flex-input' style={{ width: "48%" }}>
                            <label htmlFor='Location'>{lang === "en" ? "Profile Name" : "Profil navn"}</label>
                            <input type="text" disabled id='delegate to' value={delegateTo} onChange={(e) => getdelegateTo(e.target.value)} />
                        </div> */}

                    </div>

                    <div className='input-flex'>

                    </div>


                </div>

                <div className="modal-main" >
                    <label htmlFor='Category'>{lang === "en" ? "Profile Image" : "Profilbilde"}</label>
                    <div className='img-area-1'>
                        <div className='photo1'>
                            <img src={image ? image : user?.image ? user?.image : drop} alt="" opa={show ? 0 : 1} className="opacit" />
                            <div className='show-img'>
                                <img src={image ? image : user?.image} alt="" opa={show ? 1 : 0} className='opacit' />
                                <div className='pic-del opacit' opa={show ? 1 : 0} onClick={removeImage} ><TiDelete /></div>
                            </div>
                            <p className='drag opacit' opa={show ? 0 : 1}>{lang === "en" ? "Drap Photos here" : "Dra bilder her"}</p>
                        </div>
                        <div className='img-src-btn1'>
                            <label htmlFor="files">{lang === "en" ? "Select From Computer" : "Velg Fra datamaskin"}</label>
                            <input type="file" id="files" accept='image/*' onChange={(e) => imageHandler(e)} />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <div className='but-flex'>
                <div className='cancel'>
                    {/* <button onClick={onClick}>{lang === "en" ? "Cancel" : "Avbryt"}</button> */}
                </div>
                <div className='save-changes'>


                    {/* <button onClick={() => onUpdate()}>{lang === "en" ? "Update" : "Oppdater"}</button> */}


                </div>
                <div className='but-flex'>
                    <div className='cancel'>
                        <button onClick={onClick}>{lang === "en" ? "Cancel" : "Avbryt"}</button>
                    </div>
                    <div className='save-changes'>


                        <button onClick={() => onUpdate()}>{lang === "en" ? "Update" : "Oppdater"}</button>


                    </div>
                </div>
            </div>
        </Dialog>
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(ProfileVisibleModal));


import React from "react";
import { LOGOUT, SET_USER, CHANGE_LANG, SET_UPDATE_PROFILE } from "./actionType";

const initialState = {
    user: null,
    logged: false, lang: "en"
};

export default (state = initialState, action) => {

    const { type, payload } = action

    switch (type) {

        case SET_USER: {
            return {
                ...state,
                user: payload,
                logged: true
            };

        }
        case LOGOUT: {
            return {
                ...state,
                user: null,
                logged: false
            };

        }
        case CHANGE_LANG: {
            return {
                ...state,
                lang: payload,
            };

        }
        case SET_UPDATE_PROFILE: {
            return {
                ...state,
                user: action.payload,
            }
        }


        default:
            return state;
    }
};

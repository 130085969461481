import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Switch
} from '@material-ui/core';
import "../css/Modal.css";
import "../css/DropDown.css";
import drop from "../assets/drop.png";
import { TiDelete } from "react-icons/ti";
import { withRouter } from './withRouter';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import DynamicNotificationsForm from './DynamicNotificationsForm';

const ManageNotificationsModal = ({ open, onClick, onUpdate, data }) => {

    const [appName, setAppName] = useState("")
    const [appId, setAppId] = useState("")

    useEffect(() => {
        if (data) {
            setAppName(data?.appName)
            setAppId(data?.appId)
        }
    }, [data]);

    const onCancel = () => {
        setAppId("")
        setAppName("")
        onClick()
    }

    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <div className='close-btn' onClick={onClick} style={{ cursor: "pointer", }}><TiDelete /></div>
            <DialogTitle id="dialog-title">
                <h2>{"Manage App Notifications"}</h2>
                <div className='line'></div>
            </DialogTitle>
            <DialogContent>
                <div className="modal-main">
                    <DynamicNotificationsForm data={data} onCancel={onCancel} />
                </div>
            </DialogContent>
        </Dialog>
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}

export default connect(mapStatetoProps)(withRouter(ManageNotificationsModal));

import React, { useEffect, useState } from 'react';
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import DropDown from '../Componenets/DropDown';

import "../css/Table.css";
import Modal from '../Componenets/Modal';
import { deleteuser, getUsers } from '../Path/Path';
import { getAllByAltText } from '@testing-library/react';
import swal from "sweetalert";
import moment from 'moment';
import NewModal from './NewModal';

const Table = () => {
    const [page, setPage] = useState(0);
    const [allUsers, setAllUsers] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState("");


    const elem = 12;

    useEffect(() => {

        getAll()

    }, [])

    const getAll = async () => {

        const users = await getUsers()


        let res = users.filter(i => i.type === "guard")
        setAllUsers(res)
        console.log(res, "guards")
    }
    const onPagePress = (page) => {
        setPage(page)
    }

    const options = [
        { label: 'Approved', value: 'Approved' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Disabled', value: 'Disabled' },
    ];


    const handleChange = (e, index) => {
        console.log(page * elem + index);
        const temp = [...allUsers];
        temp[page * elem + index].statuss = e.target.value;
        setAllUsers(temp);

    };

    const modalHandler = () => {
        setVisible(true)
        setType("Add")
    }
    // const deleteHandler = async (item) => {

    //     alert(res?.message)
    //     console.log(res, "deleteeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
    // }
    const deleteHandler = async (item) => {
        swal({
            title: "Alert?",
            text: "Are you sure to delete this user?",
            icon: "warning",
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    let res = await deleteuser(item)
                    if (res?.message === 'User deleted successfully') {
                        swal("Deleted!", "User deleted successful ", "success");
                        await getAll()
                    }

                }


            })
    }
    // editHandler = (item) => {

    //     this.props.history("/addstore", { state: { item } })
    // }
    console.log(allUsers, "allUsers")
    return (
        <div className='table'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Created at</th>
                        <th>ID</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {allUsers?.length !== 0 ?
                        allUsers.slice(page * elem, (page * elem) + elem).map((elem, index) =>
                            <tr>
                                <td>{moment(elem.createdAt).format('DD/MM/YY')}</td>
                                <td>{elem._id}</td>
                                <td>{elem.userName}</td>
                                <td>{elem.email}</td>

                                <td>
                                    <p className={elem.isActive === true ? "select" : "select1"}>{elem.isActive === true ? "Approved" : "Pending"}</p>
                                </td>
                                <td>
                                    <div className='flex-edit-del'>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div className='eye'><AiOutlineEye /></div>
                                            <div className='edit' onClick={modalHandler}><FiEdit color='#FBC919' /></div>
                                            <div onClick={() => deleteHandler(elem)} className='del'><RiDeleteBinLine /></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ) : null
                    }
                </tbody>
            </table>
            <div className='account-pagi-wrap'>
                <button className='pgn-btn-prev' disabled={page === 0} onClick={() => setPage(page - 1)}>
                    <IoMdArrowDropleft className='prev-icon' />
                </button>
                <button className={page === 0 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => onPagePress(0)} >1</button>
                <button className={page === 1 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => onPagePress(1)} >2</button>
                <button className={page === 2 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => onPagePress(2)} >3</button>
                <button className={page === 3 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => onPagePress(3)} >4</button>
                <button className='pgn-btn-next' onClick={() => setPage(page + 1)}>
                    <IoMdArrowDropright className='prev-icon' disabled={page === 3} />
                </button>
            </div>
            <NewModal
                open={visible ? true : false}
                onClick={() => setVisible(false)}
                type={type}
                addEvent
            />
        </div>

    )
}

export default Table;

import React from 'react';
import "../css/DataDropDown.css";
const FixedDropDown = ({ value, options, onChange }) => {
    return (
        <label>
            <select style={{ color: "black" }} value={value} onChange={onChange}>
                {options.map((option) => (
                    <option style={{ color: "black" }} value={option.value} className="option0"  >{option.label}</option>
                ))}
            </select>
        </label>
    )
}

export default FixedDropDown;

import React, { Component } from "react";
import "../css/Login.css"
import triaden from "../assets/triaden.png"
import england from "../assets/england.png"
import america from "../assets/america.png"
import { withRouter } from "../Componenets/withRouter";
import { style } from "@mui/system";
import { CircularProgress } from '@mui/material';
import { signup } from "../Path/Path";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import action from "../store/action";

class SignUpmain extends Component {
    state = {
        userName: "Financial Manager",
        email: "financialmanager@gmail.com",
        password: "123",
        loading: false,
    }
    handler = async () => {
        const body = {
            "userName": this.state.userName,
            "email": this.state.email,
            "password": this.state.password,
            "phone": "03124567658",
            "type": "Financial Manager",
            "image": "",
            "isActive": true
        }
        if (this.state.userName === "") {
            toast.warn("Enter UserName")
        }
        else if (this.state.email === "") {
            toast.warn("Enter Email")
        }
        else if (this.state.password === "") {
            toast.warn("Enter password")
        }

        else {
            await this.setState({ loading: true })
            await signup(body)
                .then((res) => {
                    if (res?.success) {
                        toast.success(res?.message)


                        this.props.history("/")


                    }
                    else {
                        toast.warn(res?.message)
                        this.setState({ loading: false })
                    }
                }).catch((err) => {
                    toast.warn("Something went wrong")
                    this.setState({ loading: false })
                })

        }

        // this.props.history("/")
    }
    render() {
        return (
            <div>

                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={triaden}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>{this.props.lang === "en" ? "Sign up" : "S'inscrire"}</p>
                                </div>
                                {/* <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>{this.props.lang === "en" ? "User Name" : "Brukernavn"}</p>
                                </div>
                                <input value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value })} className='register-input' placeholder={this.props.lang === "en" ? 'UserName' : "Brukernavn"}></input> */}
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>{this.props.lang === "en" ? "Email" : "E-mail"}</p>
                                </div>
                                <input value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className='register-input' placeholder={this.props.lang === "en" ? 'Email Address' : "Adresse e-mail"}></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>{this.props.lang === "en" ? "Password" : "Mot de passe"}</p>
                                </div>
                                <input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className='register-input' placeholder={this.props.lang === "en" ? 'Password' : "Mot de passe"}></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    {/* <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "4%", marginTop: "2%" }}>
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                <input type="checkbox"></input>
                                            </div>
                                            <div style={{ width: "100px" }}>
                                                <p style={{ color: "grey", marginLeft: "3%", marginTop: "0", marginBottom: "4px" }}>keep me logged in</p>
                                            </div>

                                        </div>
                                        <div>
                                            <p style={{ color: "#0063F5", }}>Forgot Password?</p>
                                        </div>
                                    </div> */}
                                </div>
                                <button onClick={() => this.handler()} loading={this.state.loading} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>{this.props.lang === "en" ? "Sign up" : "S'inscrire"} </p>
                                    }

                                </button>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%", marginBottom: "2%" }}>
                                    <div className="line-grey">
                                    </div>
                                    <div><p style={{ color: "grey", margin: "0", fontSize: "20px" }}>{this.props.lang === "en" ? "or" : "ou"}</p></div>
                                    <div className="line-grey">
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <p style={{ color: "black", }}>{this.props.lang === "en" ? "Already have member?" : "Déjà membre?"}</p>
                                    </div>
                                    <div >
                                        <p onClick={() => this.props.history("/")} style={{ color: "#0063F5", fontWeight: 500, cursor: "pointer", marginLeft: "5px" }}>{this.props.lang === "en" ? "Sign in" : "S'identifier"}</p>
                                    </div>
                                </div>
                                <div style={{ width: "100%", marginTop: "8%" }}>
                                    <div style={{ width: "100%" }} >
                                        <p className="selectlanguage">Select Language</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%" }}>
                                        <div onClick={() => this.props._changeLang("nor")} style={{ marginRight: "2%", color: this.props.lang !== "en" ? " #0063F5" : "black" }}>
                                            <div style={{ marginBottom: "3%", display: "flex", justifyContent: "center", alignItems: "center", width: "40px", height: "40px" }}>
                                                <img width="100%" height="100%" src={england}></img>
                                            </div>
                                            <div>
                                                <p style={{ marginLeft: "8%" }}>French</p>
                                            </div>
                                        </div>
                                        <div onClick={() => this.props._changeLang("en")} style={{ marginLeft: "2%", color: this.props.lang === "en" ? " #0063F5" : "black" }}>
                                            <div style={{ marginBottom: "3%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img src={america}></img>
                                            </div>
                                            <div>
                                                <p style={{ marginLeft: "10%" }}>English</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="design-bottom">


                    </div> */}

                </div>

            </div >
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(SignUpmain));
import React, { useEffect, useState } from 'react';
import "../css/Dashboard.css";
import "../css/DropDown.css";
import "../css/Account.css";
import "../css/Table.css";
import { TbEditCircle } from "react-icons/tb";
import { MdOutlineEditNotifications } from 'react-icons/md';
import Header from "../Componenets/Header";
import Loader from '../Componenets/Loader';
import { addNewApp, editApp, getApps, } from '../Path/Path';
import { withRouter } from '../Componenets/withRouter';
import action from '../store/action';
import { connect } from "react-redux";
import { Switch } from '@material-ui/core';
import EditUserModal from '../Componenets/EditUserModal';
import getImageURL from '../config/uploadmageFunction';
import ManageNotificationsModal from '../Componenets/ManageNotificationsModal';

const Dashboard = (props) => {
    const [allUsers, setAllUsers] = useState([]);
    const [userResult, setUserResult] = useState([]);
    const [toggleT, setToggleT] = useState(true);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [alarmPercentage, setAlarmPercentage] = useState("");
    const [barPercentage, setBarPercentage] = useState("");
    const [allApps, setAllApps] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [NotificationModalVisible, setNotificationModalVisible] = useState(false);
    const [data, setData] = useState(null);
    const [edit, setEdit] = useState(false);
    const alaPercent = alarmPercentage.toString();
    const elem = 12;

    const getAllUsers = async () => {
        setLoading(true)
        const users = await getApps()
        // setLoading(true)
        setAllApps(users)
        setLoading(false)
    }
    useEffect(() => {

        getAllUsers()

    }, [])
    const onEdit = async (id, future, spot) => {
        setLoading(true)
        let res = await editApp(id, { futureTrading: !future, spotTrading: !spot })
        getAllUsers()
    }
    const saveChanges = async (body) => {
        setLoading(true)
        setModalVisible(false)

        if (edit && body.image !== data.image) {

            let res = await getImageURL(body.file)
            body.image = res
        }


        if (edit) {
            let resp = await editApp(data._id, body)

            getAllUsers()
        } else {
            let resp = await addNewApp(body)
            getAllUsers()
        }
    }
    const closeNotificationModal = () => {
        setLoading(true)
        setNotificationModalVisible(false)
        getAllUsers()
    }

    return (<React.Fragment>
        <Loader loading={loading} />
        <Header getLoading={(e) => setLoading(e)} head={props.lang === "en" ? "Dashboard" : "Tableau de bord"} paraDash={props.lang === "en" ? "Details about your crypto applications." : "Obtenez un résumé du nombre total d'utilisateurs, des problèmes résolus, des alarmes et des revenus."} />
        <div className='main-div1'>
            <p className='profit-para'>{"Application"}</p>

            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }} >
                <div className='save-changes'>
                    <button onClick={() => { setEdit(false); setData(null); setModalVisible(true) }} style={{ cursor: "pointer", }}>{"Create App"}</button>
                </div>
            </div>
            <div style={{ width: "100%", flex: 1, display: "flex", flexDirection: "row", flexWrap: "wrap", }} >
                {
                    allApps.map((item, index) =>
                        <div key={index} style={{ width: 350, height: 250, backgroundColor: "white", border: "1px solid #E3E3E3", borderRadius: 18, padding: 10, margin: 10, display: "flex", flexDirection: "column", justifyContent: "space-around" }} >
                            <img width={"100%"} height={120} style={{ borderRadius: 10 }} src={item?.image} alt="" />
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >

                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                        <p className='profit-para1'>{"App Name: "} </p>
                                        <p className='profit-para2' style={{ marginLeft: 10 }} >{item?.appName}</p>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10 }} >
                                        <p className='profit-para1'>{"App ID: "} </p>
                                        <p className='profit-para2' style={{ marginLeft: 10 }} >{item?.appId}</p>
                                    </div>
                                </div>
                                <div style={{ flexDirection: "row", alignItems: "center" }} >
                                    <MdOutlineEditNotifications size={24} onClick={() => { setData(item); setNotificationModalVisible(true) }} style={{ marginRight: 10, cursor: "pointer", }} color="#004BFF" />
                                    <TbEditCircle size={24} onClick={() => { setEdit(true); setData(item); setModalVisible(true) }} style={{ marginRight: 10, cursor: "pointer", }} color="#004BFF" />
                                    {/* <AiFillDelete size={24} color="red" onClick={() => console.log("")} /> */}
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                <p className='profit-para1'>{"Enable: "} </p>
                                <Switch checked={item?.futureTrading} onClick={() => onEdit(item._id, item?.futureTrading, item?.spotTrading)} />
                                {/* <input checked={item?.futureTrading} onClick={() => onEdit(item._id, item?.futureTrading, item?.spotTrading)} type="checkbox" style={{ marginLeft: 10 }} ></input> */}
                            </div>

                        </div>
                    )
                }
            </div>
            <EditUserModal
                open={modalVisible}
                onClick={() => setModalVisible(!modalVisible)}
                {...{ data }}
                onUpdate={(body) => saveChanges(body)}
            />
            <ManageNotificationsModal
                open={NotificationModalVisible}
                onClick={() => setNotificationModalVisible(!NotificationModalVisible)}
                {...{ data }}
                onUpdate={closeNotificationModal}
            />
        </div>
        {/* <Myfooter /> */}
    </React.Fragment>
    )
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(Dashboard));

import React, { useEffect, useState } from 'react';
import "../css/Account.css";
import "../css/Table.css";
import Header from '../Componenets/Header';
import { TiArrowForward, TiDelete } from "react-icons/ti";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import DropDown from '../Componenets/DropDown';

import Table from '../Componenets/Table';
import Modal from '../Componenets/Modal';
import swal from "sweetalert";
import england from "../assets/england.png"
import america from "../assets/america.png"
import { customerSupportEdit, deletenotification, getCustomerSupport, getPie } from '../Path/Path';
import moment from 'moment';
import { withRouter } from '../Componenets/withRouter';
import { connect } from "react-redux";
import action from '../store/action';
import Myfooter from '../Componenets/Myfooter';
import Loader from '../Componenets/Loader';
import DataVisibleModal from '../Componenets/VisibleComponent';
import Pagination from '../Componenets/Pagination';
import CustomerSupportVisibleComponent from '../Componenets/CustomerSupportVisibleComponent';
import CustomerSupportModal from '../Componenets/CustomerSupportModal';
import { toast } from 'react-toastify';

const CustomerSupport = (props) => {

    const [page, setPage] = useState(1);
    const [visible, setVisible] = useState([]);
    const [customerSupport, setCustomerSupport] = useState([]);
    const [toggleT, setToggleT] = useState(true);
    const [customerVisible, setCustomerVisible] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ViewVisible, setViewVisible] = useState(false);
    const [infoData, setInfoData] = useState(null);
    const [typeUser, setTypeUser] = useState('');
    const [status, setStatus] = useState(null);
    const [createdAt, setCreatedAt] = useState('');
    const [id, setId] = useState('');
    const [storeName, setStoreName] = useState('');
    const [triggeredTime, setTriggeredTime] = useState('');
    const [delegateTo, setDelegateTo] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const elem = 12;


    const [activePage, setActivePage] = useState(1);
    const [page1, setpage1] = useState(1)
    const [page2, setpage2] = useState(2)
    const [page3, setpage3] = useState(3)





    const deleteHandler = async (item) => {

        swal({
            title: `${props.lang === "en" ? "Alert?" : "Alerte?"}`,
            text: `${props.lang === "en" ? "Are you sure to delete this user detail?" : "Êtes-vous sûr de vouloir supprimer ce détail d'utilisateur ?"}`,
            icon: "warning",
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    // let res = await deletenotification(item)
                    // if (res?.success) {
                    // swal(`${props.lang === "en" ? "Deleted!" : "Supprimé!"}`, res?.message, "success");
                    swal(`${props.lang === "en" ? "Deleted!" : "Supprimé!"}`, 'User Detail Deleted Successfully!', "success");
                    //     getAllSupport()
                    // }

                }


            })


    }
    const getAllSupportWithDate = async (date) => {
        setLoading(true)
        setFilterDate(date)
        const support = await getCustomerSupport()
        let res = support.filter(i => moment(i?.createdAt).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY'))
        setCustomerSupport(res.reverse())
        setPage(1)
        console.log(res, "dateRess....................")
        setLoading(false)

    }
    const getAllSupport = async () => {
        setLoading(true)
        setFilterDate('')
        const support = await getCustomerSupport()
        setCustomerSupport(support.reverse())
        setLoading(false)
    }
    const refreshData = async () => {
        getAllSupport()
    }
    useEffect(() => {

        getAllSupport()

    }, [])
    const VisibleHandle = (item) => {
        console.log(item, 'itemm....');

        // setSelectedData(item._id)
        setInfoData(item)
        // setImgData(item?.image)
        setViewVisible(true)
        // setType("Edit")
    }
    console.log(moment(filterDate).format("DD/MM/YYYY"), "filter..............")
    const onMinus = () => {
        if (activePage === 2 || activePage === 3) {
            setActivePage(activePage - 1);
            if (activePage === 2) {
                setpage1(1);
                setpage2(2);
                setpage3(3)
            }
            if (activePage === 3) {
                setpage1(1);
                setpage2(2);
                setpage3(3)
            }
        } else if (activePage !== 1) {
            setActivePage(activePage - 1);
            setpage1(page1 - 1);
            setpage2(page2 - 1);
            setpage3(page3 - 1)
        }



    }
    const editCustomerSupport = async () => {
        setCustomerVisible(false)
        setLoading(true)
        if (selectedData) {
            if (status === '' || status === 'Select Status') {
                toast.warn('Please select status')
            }
            else {
                let body = {
                    resolved: status
                }
                let res = await customerSupportEdit(body, selectedData?._id)
                if (res?.success) {
                    setCustomerVisible(false)
                    toast.success('Customer support edited successfully')
                    await getAllSupport()
                }
            }
        }
        setLoading(false)
    }
    const editModalHandler = async (elem) => {
        setCustomerVisible(true)
        setSelectedData(elem)
        setTypeUser(elem?.resolved)
    }
    const onClickHandler = () => {
        setViewVisible(false)
        setInfoData(null)
    }
    const totalPage = parseInt((customerSupport.length / 12) + 1)
    return (
        <React.Fragment>
            <Loader loading={loading} />
            <Header getLoading={(e) => setLoading(e)} head={props.lang === "en" ? "Customer Support" : "Service client"} paraDash={props.lang === "en" ? "Get details about users who are facing issues." : "Obtenez des détails sur les utilisateurs qui rencontrent des problèmes."} />
            <div style={{ minHeight: "100vh", position: "relative", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                <div className='main-div'>
                    <div className='account-top'>
                        <div className='account-flex'>
                            <div className='account-heading'>
                                <p className='account-para'>{props.lang === "en" ? "Customer Support Details" : "Détails de l'assistance client"}</p>
                            </div>
                            <div className='account-button'>
                                {/* <div className='filter-btn'>
                                    <button type='text'>{props.lang === "en" ? "Add Filter" : "Ajouter un filtre"}</button>
                                </div> */}

                                <div onClick={() => refreshData()} className='account-icon'>
                                    <TiArrowForward />
                                </div>
                            </div>
                        </div>
                        {/* <div className='last-thirty-days'>
              <p className='thirty-days'>{props.lang === "en" ? "Date and Time is Last 30 Days" : "Dato og klokkeslett er siste 30 dager"}</p>
              <div className='cross-icon'>
                <TiDelete />
              </div>
            </div> */}
                        <div className='last-thirty-days'>
                            {/* <input value={mydate} style={{ width: "100%" }} type={"date"} className='date' placeholder={props.lang === "en" ? "Date and Time is Last 30 Days" : "Dato og klokkeslett er siste 30 dager"}></input> */}
                            <input style={{ width: "100%", border: "none" }} value={filterDate} onChange={(e) => getAllSupportWithDate(e.target.value)} className='date' id="dateRequired" type="date" name="dateRequired" />
                            {/* <p style={{ width: "100%", position: "absolute" }}>{props.lang === "en" ? "Date and Time is Last 30 Days" : "Dato og klokkeslett er siste 30 dager"}</p> */}
                            <div className='cross-icon' onClick={() => getAllSupport()}>
                                <TiDelete />
                            </div>
                        </div>
                        <div className='table'>
                            {
                                toggleT ?
                                    <React.Fragment>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>{props.lang === "en" ? "Created at" : "Créé à"}</th>
                                                    <th>{props.lang === "en" ? " ID" : "IDENTIFIANT"}</th>
                                                    <th>{props.lang === "en" ? " User Name" : "Nom d'utilisateur"}</th>
                                                    <th>{props.lang === "en" ? " Email" : "E-mail"}</th>
                                                    <th>{props.lang === "en" ? " Details" : "Détails"}</th>

                                                    <th>{props.lang === "en" ? "Status" : "Statut"}</th>
                                                    <th>{props.lang === "en" ? "Action" : "Action"}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {customerSupport.length !== 0 ? customerSupport
                                                    .slice((page - 1) * elem, (page * elem) + elem).map((elem, index) =>
                                                        <tr>
                                                            <td>{moment(elem?.createdAt).format('DD/MM/YY')}</td>
                                                            <td>{elem?._id}</td>
                                                            <td>{elem?.name}</td>
                                                            <td>{elem?.email}</td>
                                                            <td style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100px" }}>{elem?.message}</td>
                                                            {/* <td> <p className="select0">{elem?.acceptedBy?.userName}</p></td> */}
                                                            <td>
                                                                <p className={elem?.resolved === true ? "select" : "select1"}>{elem?.resolved === true ? "Resolved" : "Pending"}</p>
                                                            </td>
                                                            <td>
                                                                <div className='flex-edit-del'>

                                                                    <div onClick={() => VisibleHandle(elem)} className='eye'><AiOutlineEye /></div>
                                                                    <div className='edit' onClick={() => editModalHandler(elem)}><FiEdit color={"#FFB219"} /></div>
                                                                    {/* <div className='del' onClick={() => deleteHandler(elem)}><RiDeleteBinLine /></div> */}

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : null
                                                }
                                            </tbody>
                                        </table>
                                        <Pagination page={page} setPage={setPage} totalPage={totalPage} />
                                        {/* <div style={{ marginBottom: "4%" }} className='account-pagi-wrap'>
                      <div style={{ flexDirection: "row", alignItems: "center", display: "flex" }} >

                        <button className='pgn-btn-prev' onClick={() => { onMinus() }}>
                          <IoMdArrowDropleft className='prev-icon' />
                        </button>

                        <button className={page === page1 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => setActivePage(page1)} >{page1}</button>

                        <button className={activePage === page2 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => setActivePage(page2)} >{page2}</button>

                        <button className={activePage === page3 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => setActivePage(page3)} >{page3}</button>

                        <button className='pgn-btn-next' onClick={() => { setActivePage(activePage + 1); setpage1(page1 + 1); setpage2(page2 + 1); setpage3(page3 + 1) }}>
                          <IoMdArrowDropright className='prev-icon'   />
                        </button>
                        
                      </div>
                    </div> */}
                                    </React.Fragment>
                                    :
                                    <Table />
                            }
                        </div>
                    </div>
                    {/* <Modal
                        open={visible ? true : false}
                        onClick={() => setVisible(false)}
                    /> */}
                    <CustomerSupportVisibleComponent

                        open={ViewVisible ? true : false}
                        infoData={infoData}
                        // createdAt={createdAt}
                        // id={id}
                        // storeName={storeName}
                        // typeUser={typeUser}
                        // triggeredTime={triggeredTime}
                        // delegateTo={delegateTo}
                        // image={imgData}
                        // imageFile={imageFile}
                        onClick={() => onClickHandler()}
                    />
                    {/* <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#FFFFFF", marginTop: "2%", padding: "1%" }}>
          <div>
            <p className='triaden2022'>2022 © TRIADEN</p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <p className='select2022' style={{ marginRight: "0.5%", width: "100px" }} > Select Language</p>
            </div>
            <div>
              <img className='england-img1' width={25} height={25} src={england}></img>
            </div>
            <div>
              <p className='select2022' style={{ marginLeft: "7.5%", marginRight: "0.5%", width: "100px" }} > Nervoweign</p>
            </div>
            <div style={{ marginLeft: "-3.5%" }} >
              <img className='england-img1' width={25} height={25} src={america}></img>
            </div>
            <div>
              <p className='select2022' style={{ marginLeft: "12.5%" }} > English</p>
            </div>
          </div>


        </div> */}
                </div>
                <div className='footer-bottom'>
                    <Myfooter />
                </div>
                <CustomerSupportModal
                    open={customerVisible ? true : false}
                    onUpdate={() => editCustomerSupport()}
                    onClick={() => setCustomerVisible(false)}
                    status={status}
                    getStatus={(e) => setStatus(e)}
                />
            </div>

        </React.Fragment >
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(CustomerSupport));
import React from 'react';
import { FiEdit } from "react-icons/fi";
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { RiDeleteBinLine } from "react-icons/ri"

const Pagination = ({ page, setPage, totalPage, }) => {

    const getPageCount = (position) => {
        switch (position) {
            case "left":
                return page === 1 ? page : page - 1
            case "center":
                return page === 1 ? page + 1 : page
            case "right":
                return page === 1 ? page + 2 : page + 1

            default:
                return page
        }

    }
    return (
        <div style={{ marginBottom: "4%" }} className='account-pagi-wrap'>
            <div style={{ flexDirection: "row", alignItems: "center", display: "flex" }} >

                <button className={'pgn-btn-prev'} disabled={page === 1} onClick={() => { setPage(s => s - 1); }}>
                    <IoMdArrowDropleft className='prev-icon' />
                </button>

                <button className={page === 1 ? "pgn-btn:active" : 'pgn-btn'} onClick={() => setPage(getPageCount("left"))} >{getPageCount("left")}</button>

                <button disabled={getPageCount("center") > totalPage} className={page !== 1 ? "pgn-btn:active" : 'pgn-btn'} onClick={() => setPage(getPageCount("center"))} >{getPageCount("center")}</button>

                <button disabled={getPageCount("right") > totalPage} className={'pgn-btn'} onClick={() => setPage(getPageCount("right"))} >{getPageCount("right")}</button>

                <button className='pgn-btn-next' disabled={page >= totalPage} onClick={() => { setPage(s => s + 1); }}>
                    <IoMdArrowDropright className='prev-icon' />
                </button>

            </div>
        </div>
    )
}

export default Pagination;

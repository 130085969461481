import React, { useEffect, useState } from 'react';
import "../css/Account.css";
import "../css/Table.css";
import Header from '../Componenets/Header';
import { TiArrowForward, TiDelete } from "react-icons/ti";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import DropDown from '../Componenets/DropDown';

import Table from '../Componenets/Table';
import Modal from '../Componenets/Modal';
import swal from "sweetalert";
import england from "../assets/england.png"
import america from "../assets/america.png"
import { deletenotification, deleteuser, edituser, getPie, getUsers, resetPassword, signup } from '../Path/Path';
import moment from 'moment';
import { withRouter } from '../Componenets/withRouter';
import { connect } from "react-redux";
import action from '../store/action';
import Myfooter from '../Componenets/Myfooter';
import Loader from '../Componenets/Loader';
import DataVisibleModal from '../Componenets/VisibleComponent';
import Pagination from '../Componenets/Pagination';
import NewModal from '../Componenets/NewModal';
import { toast } from 'react-toastify';
import getImageURL from '../config/uploadmageFunction';

const AsignRoles = (props) => {

  const [page, setPage] = useState(1);

  const [allUsers, setAllUsers] = useState([]);
  const [toggleT, setToggleT] = useState(true);
  const [visible, setVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ViewVisible, setViewVisible] = useState(false);
  const [typeUser, setTypeUser] = useState('');
  const [imgData, setImgData] = useState(null)
  const [imgFile, setImgFile] = useState(null)
  const [imageFile, setImageFile] = useState(null);
  const [show, setShow] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [createdAt, setCreatedAt] = useState('');
  const [id, setId] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedData, setSelectedData] = useState(null);
  const [storeName, setStoreName] = useState('');
  const [triggeredTime, setTriggeredTime] = useState('');
  const [delegateTo, setDelegateTo] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const elem = 12;


  const [activePage, setActivePage] = useState(1);
  const [page1, setpage1] = useState(1)
  const [page2, setpage2] = useState(2)
  const [page3, setpage3] = useState(3)






  const deleteHandler = async (item) => {

    swal({
      title: `${props.lang === "en" ? "Alert?" : "Alerte?"}`,
      text: `${props.lang === "en" ? "Are you sure to delete this user?" : "Voulez-vous vraiment supprimer cet utilisateur ?"}`,
      icon: "warning",
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          let res = await deleteuser(item)
          if (res?.success) {
            swal(`${props.lang === "en" ? "Deleted!" : "Supprimé!"}`, res?.message, "success");
            swal(`${props.lang === "en" ? "Deleted!" : "Supprimé!"}`, 'User Deleted Successfully!', "success");
            await getAllUsers()
          }

        }


      })


  }
  const getAllUsersWithDate = async (date) => {
    setLoading(true)
    setFilterDate(date)
    const users = await getUsers()
    let res = users?.filter(i => moment(i?.createdAt).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY'))
    if (res.length > 0) {
      let response = res?.filter(i => i?.type !== "user" && i?._id !== props?.user?._id)
      setAllUsers(response.reverse())

    }
    else {
      setAllUsers([])
    }
    setPage(1)
    console.log(res, "dateRess....................")
    setLoading(false)

  }
  const getAllUsers = async () => {
    setLoading(true)
    setFilterDate('')
    const users = await getUsers()
    if (users?.length > 0) {
      let res = users?.filter((item, index) => item?.type !== "user" && item?._id !== props?.user?._id)
      setAllUsers(res.reverse())

    }
    setLoading(false)
  }
  const refreshData = async () => {
    getAllUsers()
  }
  useEffect(() => {

    getAllUsers()

  }, [])
  const modalHandler = (elem) => {
    setUserName(elem?.userName)
    setEmail(elem?.email)
    setPhone(elem?.phone)
    setSelectedData(elem?.image)
    setImgData(elem?.image)
    setShow(false)
    setTypeUser(elem?.type)
    setNewVisible(true)
    setSelectedId(elem?._id)

  }
  const VisibleHandle = (item) => {
    console.log(item, 'itemm....');

    // setSelectedData(item._id)
    setId(item?._id)
    setUserName(item?.userName)
    setEmail(item?.email)
    setTypeUser(item?.type)
    setPhone(item?.phone)
    // setImgData(item?.image)
    setViewVisible(true)
    // setType("Edit")
  }
  const editHandler = async (img) => {
    if (img) {
      const body = {
        "userName": userName,
        "phone": phone,
        "type": typeUser,
        "image": img,
      }
      console.log(selectedId, 'id.....');
      if (typeUser === '' || typeUser === 'Select Role') {
        toast.warn('Please select user role')
      }
      else {
        let params = {
          password: password,
        }
        if (password) {
          await resetPassword(email, params)
            .then(async (res) => {
              if (res?.success) {
                await edituser(body, selectedId).then(async (res) => {
                  if (res?.success) {
                    await getAllUsers()
                    toast.success(res?.message)
                    setUserName("")
                    setNewVisible(false)
                    setEmail("")
                    setPassword("")
                    setImgData(null)
                    setImgFile(null)
                    setTypeUser("")
                    setLoading(false)
                  }
                  else {
                    setLoading(false)
                    toast.warn("Something went wrong")
                  }
                })
                  .catch((err) => {
                    setLoading(false)
                    toast.warn("Something went wrong")
                  })
                // setNewVisible(false)
              } else {
                setPassword("")
                toast.warn(res?.message)
              }
              console.log(res, "ressssssssssssssss")

            }).catch((err) => {
              toast.warn(err.message)

            })
        }
        else {
          await edituser(body, selectedId).then(async (res) => {
            if (res?.success) {
              await getAllUsers()
              toast.success(res?.message)
              setUserName("")
              setNewVisible(false)
              setEmail("")
              setPassword("")
              setImgData(null)
              setImgFile(null)
              setTypeUser("")
              setLoading(false)
            }
            else {
              setLoading(false)
              toast.warn("Something went wrong")
            }
          })
            .catch((err) => {
              setLoading(false)
              toast.warn("Something went wrong")
            })
        }

      }


      setLoading(false)
    }
    else {
      setNewVisible(false)
      setLoading(true)
      const body = {
        "userName": userName,
        "phone": phone,
        "type": typeUser,
      }
      console.log(selectedId, 'id.....');
      if (typeUser === '' || typeUser === 'Select Role') {
        toast.warn('Please select user role')
      }
      else {
        let params = {
          password: password,
        }
        if (password) {
          await resetPassword(email, params)
            .then(async (res) => {
              if (res?.success) {
                await edituser(body, selectedId).then(async (res) => {
                  if (res?.success) {
                    await getAllUsers()
                    toast.success(res?.message)
                    setUserName("")
                    setNewVisible(false)
                    setEmail("")
                    setPassword("")
                    setImgData(null)
                    setImgFile(null)
                    setShow(false)
                    setTypeUser("")
                    setLoading(false)
                  }
                  else {
                    setLoading(false)
                    toast.warn("Something went wrong")
                  }
                })
                  .catch((err) => {
                    setLoading(false)
                    toast.warn("Something went wrong")
                  })
                // setNewVisible(false)
              } else {
                setPassword("")
                toast.warn(res?.message)
              }
              console.log(res, "ressssssssssssssss")

            }).catch((err) => {
              toast.warn(err.message)

            })
        }
        else {
          await edituser(body, selectedId).then(async (res) => {
            if (res?.success) {
              await getAllUsers()
              toast.success(res?.message)
              setUserName("")
              setNewVisible(false)
              setEmail("")
              setPassword("")
              setImgData(null)
              setImgFile(null)
              setShow(false)
              setTypeUser("")
              setLoading(false)
            }
            else {
              setLoading(false)
              toast.warn("Something went wrong")
            }
          })
            .catch((err) => {
              setLoading(false)
              toast.warn("Something went wrong")
            })
        }

      }


      setLoading(false)
    }



  }
  const uploadImageEdit = async () => {
    if (userName === '') {
      toast.warn('Please enter name')
    }
    else if (email === '') {
      toast.warn('Please enter email')
    }
    else if (typeUser === '' || typeUser === 'Select Role') {
      toast.warn('Please select user role')
    }
    else if (!phone) {
      toast.warn('Please enter contact')
    }
    else {
      if (imgData === selectedData) {
        await editHandler('')
      }
      else {
        setNewVisible(false)
        setLoading(true)
        if (imgFile) {
          let res = await getImageURL(imgFile)
          setImgData(res)
          if (res) {
            await editHandler(res)
            setLoading(false)
          }
          else {
            toast.warn('Image not uploaded please try again')
            setLoading(false)
          }
        }
        else {
          await editHandler('')
        }
      }
    }

  }
  const stateEmpty = () => {
    setVisible(false)
    setNewVisible(false)
    setViewVisible(false)
    setUserName("")
    setPhone("")
    setEmail("")
    setImgData(null)
    setImgFile(null)
    setTypeUser("")
    setPassword("")
    setShow(false)
  }
  const createHandler = async (img) => {
    setLoading(true)
    const body = {
      "userName": userName,
      "type": typeUser,
      "image": img,
      "email": email,
      "phone": phone,
      "isActive": true,
      "password": password
    }

    setVisible(false)
    if (typeUser === '' || typeUser === 'Select Role') {
      toast.warn('Please select user role')
    }
    else {
      await signup(body)
        .then(async (res) => {
          if (res?.success) {
            await getAllUsers()
            setUserName("")
            setEmail("")
            setImgData(null)
            setImgFile(null)
            setShow(false)
            setTypeUser("")
            toast.success(res?.message)
            setLoading(false)
          }
          else {
            toast.warn(res?.message)

          }
        }).catch((err) => {
          toast.warn("Something went wrong")
        })
    }


    setLoading(false)
  }
  const uploadImageCreate = async () => {
    if (userName === '') {
      toast.warn('Please enter name')
    }
    else if (email === '') {
      toast.warn('Please enter email')
    }
    else if (password === '') {
      toast.warn('Please enter password')
    }
    else if (typeUser === '' || typeUser === 'Select Role') {
      toast.warn('Please select user role')
    }
    else if (!phone) {
      toast.warn('Please enter contact')
    }
    else {
      setVisible(false)
      setLoading(true)
      if (imgFile) {
        let res = await getImageURL(imgFile)
        setImgData(res)
        if (res) {
          await createHandler(res)
          setLoading(false)
          stateEmpty()
        }
        else {
          toast.warn('Image not uploaded please try again')
          setLoading(false)
        }
      }
      else {
        await createHandler('')
        stateEmpty()
      }
    }



  }

  const totalPage = parseInt((allUsers.length / 12) + 1)
  return (
    <React.Fragment>
      <Loader loading={loading} />
      <Header getLoading={(e) => setLoading(e)} head={props.lang === "en" ? "Assign Roles" : "Attribuer des rôles"} paraDash={props.lang === "en" ? "You can accept alarms, view alarms, delegate alarms and receive alarms." : "Vous pouvez accepter des alarmes, afficher des alarmes, déléguer des alarmes et recevoir des alarmes."} />
      <div style={{ minHeight: "100vh", position: "relative", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
        <div className='main-div'>
          <div className='account-top'>
            <div className='account-flex'>
              <div className='account-heading'>
                <p className='account-para'>{props.lang === "en" ? "Assign Roles" : "Attribuer des rôles"}</p>
              </div>
              <div className='account-button'>
                <div className='filter-btn'>
                  <button onClick={() => setVisible(true)} type='text'>{props.lang === "en" ? "Create User" : "Créer un utilisateur"}</button>
                </div>

                <div onClick={() => refreshData()} className='account-icon'>
                  <TiArrowForward />
                </div>
              </div>
            </div>
            {/* <div className='last-thirty-days'>
              <p className='thirty-days'>{props.lang === "en" ? "Date and Time is Last 30 Days" : "Dato og klokkeslett er siste 30 dager"}</p>
              <div className='cross-icon'>
                <TiDelete />
              </div>
            </div> */}
            <div className='last-thirty-days'>
              {/* <input value={mydate} style={{ width: "100%" }} type={"date"} className='date' placeholder={props.lang === "en" ? "Date and Time is Last 30 Days" : "Dato og klokkeslett er siste 30 dager"}></input> */}
              <input style={{ width: "100%", border: "none" }} value={filterDate} onChange={(e) => getAllUsersWithDate(e.target.value)} className='date' id="dateRequired" type="date" name="dateRequired" />
              {/* <p style={{ width: "100%", position: "absolute" }}>{props.lang === "en" ? "Date and Time is Last 30 Days" : "Dato og klokkeslett er siste 30 dager"}</p> */}
              <div className='cross-icon' onClick={() => getAllUsers()}>
                <TiDelete />
              </div>
            </div>
            <div className='table'>
              {
                toggleT ?
                  <React.Fragment>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>{props.lang === "en" ? "Created at" : "Créé à"}</th>
                          <th>{props.lang === "en" ? "ID" : "IDENTIFIANT"}</th>
                          <th>{props.lang === "en" ? "User Name" : "Nom d'utilisateur"}</th>
                          <th>{props.lang === "en" ? "Email" : "E-mail"}</th>
                          <th>{props.lang === "en" ? "Contact" : "Contact"}</th>
                          <th>{props.lang === "en" ? "Status" : "Statut"}</th>
                          <th>{props.lang === "en" ? "Action" : "Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allUsers.length !== 0 ? allUsers
                          .slice((page - 1) * elem, (page * elem) + elem).map((elem, index) =>
                            <tr>
                              <td>{moment(elem?.createdAt).format('DD/MM/YY')}</td>
                              <td>{elem?._id}</td>
                              <td>{elem?.userName}</td>
                              <td >{elem?.email}</td>
                              <td>{elem?.phone}</td>
                              {/* <td> <p className="select0">{elem?.acceptedBy?.userName}</p></td> */}
                              <td>
                                <p className={elem?.type === "admin" ? "select" : elem?.type === "financial manager" ? "select2" : "select1"}>{elem?.type === 'admin' ? props.lang === "en" ? "Admin" : "Administrateur" : elem?.type === 'financial manager' ? props.lang === "en" ? "Financial Manager" : "Manager financier" : props.lang === "en" ? "Customer Support" : "Service client"}</p>
                              </td>
                              <td>
                                <div className='flex-edit-del'>

                                  <div onClick={() => VisibleHandle(elem)} className='eye'><AiOutlineEye /></div>
                                  <div className='edit' onClick={() => modalHandler(elem)}><FiEdit color={"#FFB219"} /></div>
                                  <div className='del' onClick={() => deleteHandler(elem)}><RiDeleteBinLine /></div>

                                </div>
                              </td>
                            </tr>
                          ) : null
                        }
                      </tbody>
                    </table>
                    <Pagination page={page} setPage={setPage} totalPage={totalPage} />
                    {/* <div style={{ marginBottom: "4%" }} className='account-pagi-wrap'>
                      <div style={{ flexDirection: "row", alignItems: "center", display: "flex" }} >

                        <button className='pgn-btn-prev' onClick={() => { onMinus() }}>
                          <IoMdArrowDropleft className='prev-icon' />
                        </button>

                        <button className={page === page1 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => setActivePage(page1)} >{page1}</button>

                        <button className={activePage === page2 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => setActivePage(page2)} >{page2}</button>

                        <button className={activePage === page3 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => setActivePage(page3)} >{page3}</button>

                        <button className='pgn-btn-next' onClick={() => { setActivePage(activePage + 1); setpage1(page1 + 1); setpage2(page2 + 1); setpage3(page3 + 1) }}>
                          <IoMdArrowDropright className='prev-icon'   />
                        </button>
                        
                      </div>
                    </div> */}
                  </React.Fragment>
                  :
                  <Table />
              }
            </div>
          </div>
          <Modal
            open={visible ? true : false}
            onClick={() => stateEmpty()}
            onCreate={() => uploadImageCreate()}
            getUserName={(e) => setUserName(e)}
            getShow={(e) => setShow(e)}
            getEmail={(e) => setEmail(e)}
            getPassword={(e) => setPassword(e)}
            getImage={(e) => setImgData(e)}
            getImageFile={(e) => setImageFile(e)}
            getFile={(e) => setImgFile(e)}
            getPhone={(e) => setPhone(e)}
            getUserType={(e) => setTypeUser(e)}
            userName={userName}
            email={email}
            show={show}
            image={imgData}
            imageFile={imageFile}
            password={password}
            phone={phone}
            typeUser={typeUser}
          />
          <NewModal
            open={newVisible ? true : false}
            onClick={() => stateEmpty()}
            onUpdate={() => uploadImageEdit()}
            getUserName={(e) => setUserName(e)}
            getShow={(e) => setShow(e)}
            getEmail={(e) => setEmail(e)}
            getPassword={(e) => setPassword(e)}
            getImage={(e) => setImgData(e)}
            getImageFile={(e) => setImageFile(e)}
            getFile={(e) => setImgFile(e)}
            getPhone={(e) => setPhone(e)}
            getUserType={(e) => setTypeUser(e)}
            userName={userName}
            email={email}
            show={show}
            image={imgData}
            imageFile={imageFile}
            password={password}
            phone={phone}
            typeUser={typeUser}

          // getMallId={(e)=>asignFloor(e)}
          // {...{ selectedData }}
          />
          <DataVisibleModal

            open={ViewVisible ? true : false}
            userName={userName}
            typeUser={typeUser}
            email={email}
            contact={phone}
            // image={imgData}
            // imageFile={imageFile}
            onClick={() => stateEmpty()}
          />
          {/* <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#FFFFFF", marginTop: "2%", padding: "1%" }}>
          <div>
            <p className='triaden2022'>2022 © TRIADEN</p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <p className='select2022' style={{ marginRight: "0.5%", width: "100px" }} > Select Language</p>
            </div>
            <div>
              <img className='england-img1' width={25} height={25} src={england}></img>
            </div>
            <div>
              <p className='select2022' style={{ marginLeft: "7.5%", marginRight: "0.5%", width: "100px" }} > Nervoweign</p>
            </div>
            <div style={{ marginLeft: "-3.5%" }} >
              <img className='england-img1' width={25} height={25} src={america}></img>
            </div>
            <div>
              <p className='select2022' style={{ marginLeft: "12.5%" }} > English</p>
            </div>
          </div>


        </div> */}
        </div>
        <div className='footer-bottom'>
          <Myfooter />
        </div>
      </div>

    </React.Fragment >
  )
}

const mapStatetoProps = (state) => {
  return {
    user: state.user,
    lang: state.lang
  }
}
const mapDispatchtoProps = (dispatch) => {
  return {
    _setUsers: payload => dispatch(action.setUsers(payload)),
    _changeLang: payload => dispatch(action.changeLang(payload)),

  }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(AsignRoles));
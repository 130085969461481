import React from 'react'
import england from "../assets/england.png"
import america from "../assets/america.png"
import { connect } from 'react-redux'
import action from '../store/action'

function Myfooter(props) {
    return (
        <div>
            <div style={{ width: "93%", display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#FFFFFF", marginTop: "2%", padding: "1%", paddingRight: "0%", marginLeft: "3.5%", marginRight: "3.5%" }}>
                <div>
                    <p className='triaden2022'>2022 © Safee</p>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginRight: "1%" }}>
                    <div>
                        <p className='select2022' style={{ marginRight: "0.5%", width: "100px" }} > Select Language</p>
                    </div>
                    <div style={{ cursor: "pointer" }} className='footer-color' onClick={() => props._changeLang("nor")} >
                        <div >
                            <img className='england-img1' width={25} height={25} src={england}></img>
                        </div>
                        <div>
                            <p className='select2022' style={{ marginLeft: "7.5%", marginRight: "0.5%", width: "100px", marginTop: "6%", color: props.lang !== "en" ? " #0063F5" : "black" }} > French</p>
                        </div>
                    </div>
                    <div onClick={() => props._changeLang("en")} style={{ marginLeft: "-3.5%", cursor: "pointer" }} >
                        <img className='england-img1' width={25} height={25} src={america}></img>
                    </div>
                    <div>
                        <p className='select2022' style={{ marginRight: "5%", marginLeft: "12.5%", color: props.lang === "en" ? "#0063F5" : "black" }} > English</p>
                    </div>
                </div>


            </div>
        </div>
    )
}

const mapDispatch = dispatch => {
    return {
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}

export default connect(state => state, mapDispatch)(Myfooter)

import React, { Component } from "react";
import "../css/ForgotPassword.css";
import hermoso from "../assets/triaden.png"
import { withRouter } from '../Componenets/withRouter';
import { login, resetPassword } from "../Path/Path";
import { toast } from "react-toastify";
import { CircularProgress } from '@mui/material';
import jwt_decode from "jwt-decode";

import { connect } from "react-redux";
import store from "../store";
import action from "../store/action";
import england from "../assets/england.png"
import america from "../assets/america.png"
class NewPassword extends Component {
    state = {
        email: this.props.location?.state?.email,
        password: "",
        confirmPassword: "",
        loading: "",
    }
    changePasswordHandler = async () => {
        this.setState({ loading: true })
        if (this.state.password === "") {
            toast.warn("Enter New Password")

        }
        else if (this.state.confirmPassword === '') {
            toast.warn("Enter Confirm Password")
        }

        else {
            if (this.state.confirmPassword === this.state.password) {
                let params = {
                    password: this.state.password,
                }
                await resetPassword(this.state.email, params)
                    .then((res) => {
                        if (res?.success) {
                            this.props.history("/")
                        } else {
                            toast.warn(res?.message)
                        }
                        console.log(res, "ressssssssssssssss")

                    }).catch((err) => {
                        toast.warn(err.message)

                    })
            }
            else {
                toast.warn('Your password is not matched')
            }


        }
        // this.props.history("/")
        this.setState({ loading: false })
    }
    render() {

        return (
            <div>

                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={hermoso}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>{this.props.lang ==='en' ? 'Reset Password' : 'réinitialiser le mot de passe'}</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>{this.props.lang ==='en' ? 'New Password' : 'nouveau mot de passe'}</p>
                                </div>
                                <input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className='register-input' placeholder={this.props.lang ==='en' ? 'New Password' : 'nouveau mot de passe'}></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>{this.props.lang === 'en' ? 'Confirm Password' : 'Confirmez le mot de passe'}</p>
                                </div>
                                <input type="password" value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} className='register-input' placeholder={this.props.lang ==='en' ? 'Re-enter password' : 'Entrez à nouveau le mot de passe'}></input>

                                <button onClick={() => this.changePasswordHandler()} loading={this.state.loading} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>{this.props.lang ==='en' ? 'Reset' : 'Réinitialiser'}</p>
                                    }
                                </button>

                                {/* <div style={{ width: "100%", marginTop: "8%" }}>
                                    <div style={{ width: "100%" }} >
                                        <p className="selectlanguage">{this.props.lang === 'en' ? 'Select Language' : 'Choisir la langue'}</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%" }}>
                                        <div onClick={() => this.props._changeLang("nor")} style={{ marginRight: "2%", color: this.props.lang !== "en" ? " #0063F5" : "black" }}>
                                            <div style={{ marginBottom: "3%", display: "flex", justifyContent: "center", alignItems: "center", width: "40px", height: "40px" }}>
                                                <img width="100%" height="100%" src={england}></img>
                                            </div>
                                            <div>
                                                <p>French</p>
                                            </div>
                                        </div>
                                        <div onClick={() => this.props._changeLang("en")} style={{ marginLeft: "2%", color: this.props.lang === "en" ? " #0063F5" : "black" }}>
                                            <div style={{ marginBottom: "3%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img src={america}></img>
                                            </div>
                                            <div>
                                                <p style={{ marginLeft: "10%" }}>English</p>
                                            </div>
                                        </div>
                                    </div>

                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="design-bottom">


                    </div> */}

                </div>

            </div >
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(NewPassword));
import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import "../css/Modal.css";
import DropDown from './DropDown';
import "../css/DropDown.css";
import drop from "../assets/drop.png";
import { TiDelete } from "react-icons/ti";
import { withRouter } from './withRouter';
import { connect } from 'react-redux';
import action from '../store/action';

const NewModal = ({ mallDrop, open, onClick, type, active, password, getPassword, allMalls, floors, getFloors, getUserName, onUpdate, getEmail, getUserType, getLocation, getImage, getFile, userName, email, location, getMallId, typeUser, mallID, image, lang, phone, getPhone, show, getShow }) => {


    const imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                getImage(reader.result);
            }
        }
        getShow(true);
        getFile(e.target.files[0])
        reader.readAsDataURL(e.target.files[0])
    }


    const removeImage = () => {
        getImage(null)
        getFile(null)
        getShow(false)
    }
    console.log(image);


    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <div className='close-btn' onClick={onClick}><TiDelete /></div>
            <DialogTitle id="dialog-title">
                <h2>{lang === "en" ? "Edit User Detail" : "Modifier les détails de l'utilisateur"}</h2>
                <div className='line'></div>
            </DialogTitle>
            <DialogContent>
                <div className="modal-main" >
                    <label htmlFor='username'>{lang === "en" ? "User Name" : "Nom d'utilisateur"}</label>
                    <input type="text" id='username' value={userName} onChange={(e) => getUserName(e.target.value)} placeholder={lang === "en" ? "UserName" : "Nom d'utilisateur"} />
                    <label htmlFor='username'>{lang === "en" ? "Email" : "E-mail"}</label>
                    <input type="text" disabled={true} id='email' value={email} onChange={(e) => getEmail(e.target.value)} placeholder={lang === "en" ? "Email" : "E-mail"} />
                    <label htmlFor='username'>{lang === "en" ? "Change Password" : "Changer le mot de passe"}</label>
                    <input type="password" id='password' value={password} onChange={(e) => getPassword(e.target.value)} placeholder={lang === "en" ? "Change Password" : "Changer le mot de passe"} />
                    <label htmlFor='account-type'>{lang === "en" ? "Contact" : "Contact"}</label>
                    <input type="text" id='Contact Number' value={phone} onChange={(e) => getPhone(e.target.value)} placeholder={lang === "en" ? "Contact" : "Contact"} />
                </div>
                <div className='drop'>
                    <label style={{ fontSize: "12px", color: "#272B41" }}>{lang === "en" ? "Assign Role" : "Attribuer un rôle"}</label>
                    <DropDown
                        options={[
                            { label: lang === "en" ? "Select Role" : "Sélectionnez un rôle", value: 'Select Role' },
                            { label: lang === "en" ? "Admin" : "Administratrice", value: 'admin' },
                            { label: lang === "en" ? "Financial Manager" : "Manager financier", value: 'financial manager' },
                            { label: lang === "en" ? "Customer Support" : "Service client", value: 'customer support' },
                        ]}
                        onChange={e => getUserType(e.target.value)}
                        value={typeUser}
                    />
                </div>
                <div className="modal-main" >
                    <label htmlFor='Category'>{lang === "en" ? "Profile Image" : "Profilbilde"}</label>
                    <div className='img-area-1'>
                        <div className='photo1'>
                            <img src={image ? image : drop} alt="" opa={show ? 0 : 1} className="opacit" />
                            <div className='show-img'>
                                <img src={image} alt="" opa={show ? 1 : 0} className='opacit' />
                                <div className='pic-del opacit' opa={show ? 1 : 0} onClick={removeImage} ><TiDelete /></div>
                            </div>
                            <p className='drag opacit' opa={show ? 0 : 1}>{lang === "en" ? "Drap Photos here" : "Dra bilder her"}</p>
                        </div>
                        <div className='img-src-btn1'>
                            <label htmlFor="files">{lang === "en" ? "Select From Computer" : "Velg Fra datamaskin"}</label>
                            <input type="file" id="files" accept='image/*' onChange={imageHandler} />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <div className='but-flex'>
                <div className='cancel'>
                    <button onClick={onClick}>{lang === "en" ? "Cancel" : "Annuler"}</button>
                </div>
                <div className='save-changes'>
                    <button onClick={() => onUpdate()}>{lang === "en" ? "Save" : "sauvegarder"}</button>
                </div>
            </div>
        </Dialog>
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(NewModal));

import { initializeApp } from 'firebase/app';
import { getStorage, } from "firebase/storage";
const firebaseConfig = {
    apiKey: "AIzaSyC8gojG5TJFuV0fuSdOYHyN_jm84cCOp7g",
    authDomain: "safee-8c5c6.firebaseapp.com",
    projectId: "safee-8c5c6",
    storageBucket: "safee-8c5c6.appspot.com",
    messagingSenderId: "207589969771",
    appId: "1:207589969771:web:d7763335bbaadee776462e",
    measurementId: "G-HRHB8PTV64"
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import {TimePicker} from 'formik-material-ui-pickers';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import '../css/notifications.css';
import { useEffect, useState } from "react";
import { deleteNotificationsById, getNotificationsById, upsertNotifications } from "../Path/Path";
import swal from "sweetalert";

function DynamicNotificationsForm(props) {

    const { onCancel, data } = props;

    const [notifications, setNotifications] = useState({ notifications: [], });

    const handleSubmit = async (values) => {
        var elements = values.notifications.map(i => {
            return {
                message: i.message,
                heure: moment(i.heure, "HH:mm").format("HH:mm"),
                appId: data.appId,
            }
        });
        var payload = { elements };
        if(values.notifications.length > 0) {
            const postData = await upsertNotifications(payload);
            console.log(postData);
            if(postData.acknowledged || postData.success) {
                swal("Updated!", "Notifications Updated successful ", "success");
                onCancel();
            } else {
                swal({
                    title: "Error!",
                    text: "Notifications Update Error",
                    icon: "error",
                    dangerMode: true,
                });
            }
        } else {
            const DeleteNotifications = await deleteNotificationsById(data.appId);
            console.log(DeleteNotifications);
            if(DeleteNotifications.success) {
                swal("Updated!", "Notifications Updated successful ", "success");
                onCancel();
            } else {
                swal({
                    title: "Error!",
                    text: "Notifications Update Error",
                    icon: "error",
                    dangerMode: true,
                });
            }
        }
    };

    useEffect(() => {
        if(!data.appId){
            onCancel();
        } else {
            loadNotifications(data.appId);
        }
    }, [data]);

    const loadNotifications = async (appId) => {
        var dataNotifications = await getNotificationsById(appId);
        dataNotifications = dataNotifications.map(i => {
            return {
                message: i.message,
                heure: moment(i.heure, "HH:mm"),
                appId: i.appId,
            }
        })
        setNotifications({ notifications: dataNotifications, });
    }
    
    return (
        <div className="container m-3">
        <Formik
            // initialValues={{ notifications: [{ message: "", heure: moment("16:30", "HH:mm"), }] }}
            initialValues={notifications}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
                notifications: Yup.array()
                  .of(
                    Yup.object().shape({
                      message: Yup.string().required("Message required")
                    })
                  )
              })
            }
            render={({ values, errors }) => {
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Form>
                            <FieldArray
                                name="notifications"
                                render={(arrayHelpers) => (
                                    <div className="">
                                        {
                                            values.notifications.length < 6 && (
                                                <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    onClick={() =>
                                                        arrayHelpers.insert(values.notifications.length, {
                                                            message: "",
                                                            Heure: moment(`${Math.random()*23}:30`, "HH:mm")
                                                        })
                                                    }
                                                    style={{
                                                        fontSize: "12px",
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    Add Notification
                                                </button>
                                            )
                                        }
                                        {values.notifications.map((time, index) => (
                                            <div className="row" style={{ alignItems: "center", }} key={index}>
                                                <div className="col-7">
                                                    <div className="mb-3" style={{ position: "relative", }}>
                                                        <label htmlFor="" className="form-label">
                                                            Message
                                                        </label>
                                                        <Field
                                                            className="form-control"
                                                            name={`notifications.${index}.message`}
                                                            placeholder="Notification Message"
                                                        />
                                                        <div className="form-text text-danger" style={{ position: "absolute", bottom: '-20px', left: "0px", }}>
                                                            <ErrorMessage
                                                                name={`notifications.${index}.message`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="mb-3">
                                                        <label htmlFor="" className="form-label">
                                                            Hour
                                                        </label>
                                                        <Field
                                                            component={TimePicker}
                                                            className="form-control timePickerStyle"
                                                            name={`notifications.${index}.heure`}
                                                            ampm={false}
                                                            openTo="hours"
                                                            views={['hours', 'minutes']}
                                                            format="HH:mm"
                                                        />
                                                    </div>
                                                </div>
                            
                                                <div className="col-1">
                                                    <button
                                                        className="btn btn-sm btn-danger m-2"
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                        <div className='but-flex'>
                                            <div className='cancel'>
                                                <div
                                                    onClick={() => { onCancel() }}
                                                >
                                                    {"Cancel"}
                                                </div>
                                            </div>
                                            <div className='save-changes'>
                                                <button className="btn btn btn-primary" type="submit">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        </Form>
                    </MuiPickersUtilsProvider>
                )
            }}
        />
        </div>
    );
}

export default DynamicNotificationsForm;
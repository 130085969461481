import React, { useEffect, useState } from 'react';
import "../css/Account.css";
import "../css/Table.css";
import Header from '../Componenets/Header';
import { TiArrowForward, TiDelete } from "react-icons/ti";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import DropDown from '../Componenets/DropDown';

import Table from '../Componenets/Table';
import Modal from '../Componenets/Modal';
import swal from "sweetalert";
import england from "../assets/england.png"
import america from "../assets/america.png"
import { deletenotification, getPie } from '../Path/Path';
import moment from 'moment';
import { withRouter } from '../Componenets/withRouter';
import { connect } from "react-redux";
import action from '../store/action';
import Myfooter from '../Componenets/Myfooter';
import Loader from '../Componenets/Loader';
import DataVisibleModal from '../Componenets/VisibleComponent';
import Pagination from '../Componenets/Pagination';
import EmergencyVisibleComponent from '../Componenets/EmergencyVisibleComponent';
import DataDropDown from '../Componenets/DataDropDown';

const EmergencyDetails = (props) => {

    const [page, setPage] = useState(1);

    const [allUsers, setAllUsers] = useState([]);
    const [toggleT, setToggleT] = useState(true);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ViewVisible, setViewVisible] = useState(false);
    const [typeUser, setTypeUser] = useState('');
    const [emergency, setEmergency] = useState([]);
    const [infoData, setInfoData] = useState(null);
    const [createdAt, setCreatedAt] = useState('');
    const [id, setId] = useState('');
    const [storeName, setStoreName] = useState('');
    const [triggeredTime, setTriggeredTime] = useState('');
    const [delegateTo, setDelegateTo] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const elem = 12;


    const [activePage, setActivePage] = useState(1);
    const [page1, setpage1] = useState(1)
    const [page2, setpage2] = useState(2)
    const [page3, setpage3] = useState(3)




    const deleteHandler = async (item) => {

        swal({
            title: `${props.lang === "en" ? "Alert?" : "Alerte?"}`,
            text: `${props.lang === "en" ? "Are you sure to delete this emergency detail?" : "Voulez-vous vraiment supprimer ce détail d'urgence ?"}`,
            icon: "warning",
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    // let res = await deletenotification(item)
                    // if (res?.success) {
                    // swal(`${props.lang === "en" ? "Deleted!" : "Supprimé!"}`, res?.message, "success");
                    swal(`${props.lang === "en" ? "Deleted!" : "Supprimé!"}`, 'Emergency Detail Deleted Successfully!', "success");
                    //     getAllAlarms()
                    // }

                }


            })


    }
    const getAllAlarmsWithDate = async (date) => {
        setLoading(true)
        setFilterDate(date)
        const alarms = await getPie()
        let res = alarms.filter(i => moment(i?.createdAt).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY'))
        let response = res.filter((item, index) => item?.type === 'emergency')
        setAllUsers(response.reverse())
        setPage(1)
        console.log(res, "dateRess....................")
        setLoading(false)

    }
    const getAllAlarms = async () => {
        setLoading(true)
        setFilterDate('')
        const users = await getPie()
        let res = users.filter((item, index) => item?.type === 'emergency')
        setAllUsers(res.reverse())
        setLoading(false)
    }
    const refreshData = async () => {
        getAllAlarms()
    }
    useEffect(() => {

        getAllAlarms()

    }, [])
    const VisibleHandle = (item) => {
        console.log(item, 'itemm....');

        // setSelectedData(item._id)
        setInfoData(item)
        // setCreatedAt(moment(item?.createdAt).format('DD/MM/YYYY'))
        // setId(item?._id)
        // setStoreName(item?.createdBy?.userName)
        // setTriggeredTime(moment(item?.createdAt).format('hh:mm A'))
        // setDelegateTo(item?.acceptedBy?.userName)
        // setEmergency(item?.userIDs)
        // setImgData(item?.image)
        setViewVisible(true)
        // setType("Edit")
    }
    console.log(moment(filterDate).format("DD/MM/YYYY"), "filter..............")
    const onMinus = () => {
        if (activePage === 2 || activePage === 3) {
            setActivePage(activePage - 1);
            if (activePage === 2) {
                setpage1(1);
                setpage2(2);
                setpage3(3)
            }
            if (activePage === 3) {
                setpage1(1);
                setpage2(2);
                setpage3(3)
            }
        } else if (activePage !== 1) {
            setActivePage(activePage - 1);
            setpage1(page1 - 1);
            setpage2(page2 - 1);
            setpage3(page3 - 1)
        }



    }

    const totalPage = parseInt((allUsers.length / 12) + 1)
    return (
        <React.Fragment>
            <Loader loading={loading} />
            <Header getLoading={(e) => setLoading(e)} head={props.lang === "en" ? "Emergency Details" : "Détails d'urgence"} paraDash={props.lang === "en" ? "Get emergency details of the users." : "Obtenez les détails d'urgence des utilisateurs."} />
            <div style={{ minHeight: "100vh", position: "relative", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                <div className='main-div'>
                    <div className='account-top'>
                        <div className='account-flex'>
                            <div className='account-heading'>
                                <p className='account-para'>{props.lang === "en" ? "Emergency Details" : "Détails d'urgence"}</p>
                            </div>
                            <div className='account-button'>
                                {/* <div className='filter-btn'>
                                    <button type='text'>{props.lang === "en" ? "Add Filter" : "Ajouter un filtre"}</button>
                                </div> */}

                                <div onClick={() => refreshData()} className='account-icon'>
                                    <TiArrowForward />
                                </div>
                            </div>
                        </div>
                        {/* <div className='last-thirty-days'>
              <p className='thirty-days'>{props.lang === "en" ? "Date and Time is Last 30 Days" : "Dato og klokkeslett er siste 30 dager"}</p>
              <div className='cross-icon'>
                <TiDelete />
              </div>
            </div> */}
                        <div className='last-thirty-days'>
                            {/* <input value={mydate} style={{ width: "100%" }} type={"date"} className='date' placeholder={props.lang === "en" ? "Date and Time is Last 30 Days" : "Dato og klokkeslett er siste 30 dager"}></input> */}
                            <input style={{ width: "100%", border: "none" }} value={filterDate} onChange={(e) => getAllAlarmsWithDate(e.target.value)} className='date' id="dateRequired" type="date" name="dateRequired" />
                            {/* <p style={{ width: "100%", position: "absolute" }}>{props.lang === "en" ? "Date and Time is Last 30 Days" : "Dato og klokkeslett er siste 30 dager"}</p> */}
                            <div className='cross-icon' onClick={() => getAllAlarms()}>
                                <TiDelete />
                            </div>
                        </div>
                        <div className='table'>
                            {
                                toggleT ?
                                    <React.Fragment>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>{props.lang === "en" ? "Created at" : "Créé à"}</th>
                                                    <th>{props.lang === "en" ? " ID" : "IDENTIFIANT"}</th>
                                                    <th>{props.lang === "en" ? " User Name" : "Nom d'utilisateur"}</th>
                                                    <th>{props.lang === "en" ? " Emergency Contact" : "personne à contacter en cas d'urgence"}</th>

                                                    <th>{props.lang === "en" ? "Location" : "Emplacement"}</th>
                                                    <th>{props.lang === "en" ? "Status" : "Statut"}</th>
                                                    <th>{props.lang === "en" ? "Action" : "Action"}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allUsers.length !== 0 ? allUsers
                                                    .slice((page - 1) * elem, (page * elem) + elem).map((elem, index) =>
                                                        <tr>
                                                            <td>{moment(elem?.createdAt).format('DD/MM/YY')}</td>
                                                            <td>{elem?._id}</td>
                                                            <td>{elem?.createdBy?.userName}</td>
                                                            <td>
                                                                {
                                                                    <DataDropDown
                                                                        options={elem.userIDs.map(i => ({
                                                                            label: i.userName, value: i.userName
                                                                        }))}
                                                                    // onChange={e => getUserType(e.target.value)}
                                                                    // value={typeUser}
                                                                    />
                                                                }
                                                            </td>
                                                            {/* <td style={{ color: "#EA0606" }}>{elem?.userIDs[0]?.userName}</td> */}
                                                            <td>
                                                                {elem?.address ? elem?.address : props.lang === 'en' ? "Unnamed Location" : "Emplacement sans nom"}
                                                            </td>
                                                            {/* <td> <p className="select0">{elem?.acceptedBy?.userName}</p></td> */}
                                                            <td>
                                                                <p className={elem?.resolved === true ? "select" : "select1"}>{elem?.resolved === true ? "Resolved" : "Pending"}</p>
                                                            </td>
                                                            <td>
                                                                <div className='flex-edit-del'>

                                                                    <div onClick={() => VisibleHandle(elem)} className='eye'><AiOutlineEye /></div>
                                                                    {/* <div className='edit' onClick={""}><FiEdit color={"#FFB219"} /></div> */}
                                                                    {/* <div className='del' onClick={() => deleteHandler(elem)}><RiDeleteBinLine /></div> */}

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : null
                                                }
                                            </tbody>
                                        </table>
                                        <Pagination page={page} setPage={setPage} totalPage={totalPage} />
                                        {/* <div style={{ marginBottom: "4%" }} className='account-pagi-wrap'>
                      <div style={{ flexDirection: "row", alignItems: "center", display: "flex" }} >

                        <button className='pgn-btn-prev' onClick={() => { onMinus() }}>
                          <IoMdArrowDropleft className='prev-icon' />
                        </button>

                        <button className={page === page1 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => setActivePage(page1)} >{page1}</button>

                        <button className={activePage === page2 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => setActivePage(page2)} >{page2}</button>

                        <button className={activePage === page3 ? 'pgn-btn:active' : 'pgn-btn'} onClick={() => setActivePage(page3)} >{page3}</button>

                        <button className='pgn-btn-next' onClick={() => { setActivePage(activePage + 1); setpage1(page1 + 1); setpage2(page2 + 1); setpage3(page3 + 1) }}>
                          <IoMdArrowDropright className='prev-icon'   />
                        </button>
                        
                      </div>
                    </div> */}
                                    </React.Fragment>
                                    :
                                    <Table />
                            }
                        </div>
                    </div>
                    <Modal
                        open={visible ? true : false}
                        onClick={() => setVisible(false)}
                    />
                    <EmergencyVisibleComponent

                        open={ViewVisible ? true : false}
                        infoData={infoData}

                        // image={imgData}
                        // imageFile={imageFile}
                        onClick={() => setViewVisible(false)}
                    />
                    {/* <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#FFFFFF", marginTop: "2%", padding: "1%" }}>
          <div>
            <p className='triaden2022'>2022 © TRIADEN</p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <p className='select2022' style={{ marginRight: "0.5%", width: "100px" }} > Select Language</p>
            </div>
            <div>
              <img className='england-img1' width={25} height={25} src={england}></img>
            </div>
            <div>
              <p className='select2022' style={{ marginLeft: "7.5%", marginRight: "0.5%", width: "100px" }} > Nervoweign</p>
            </div>
            <div style={{ marginLeft: "-3.5%" }} >
              <img className='england-img1' width={25} height={25} src={america}></img>
            </div>
            <div>
              <p className='select2022' style={{ marginLeft: "12.5%" }} > English</p>
            </div>
          </div>


        </div> */}
                </div>
                <div className='footer-bottom'>
                    <Myfooter />
                </div>
            </div>

        </React.Fragment >
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(EmergencyDetails));
import React, { Component } from "react";
import "../css/ForgotPassword.css";
import hermoso from "../assets/triaden.png"
import { withRouter } from '../Componenets/withRouter';
import { login, verifyCodeForget } from "../Path/Path";
import { toast } from "react-toastify";
import england from "../assets/england.png"
import america from "../assets/america.png"
import { CircularProgress } from '@mui/material';
import jwt_decode from "jwt-decode";

import { connect } from "react-redux";
import store from "../store";
import action from "../store/action";
class ForgotPassword extends Component {
    state = {
        email: "",

        loading: "",
    }
    loginHandler = async () => {
        this.setState({ loading: true })
        if (this.state.email === "") {
            toast.warn("Enter Email")

        }

        else {

            let params = {
                email: this.state.email,
                type: "service provider"
            }
            await verifyCodeForget(params)
                .then((res) => {
                    if (res?.success) {
                        this.props.history("/resetpassword", { state: { code: res?.code, email: this.state.email } })
                    } else {
                        toast.warn(res?.message)
                    }
                    console.log(res, "ressssssssssssssss")

                }).catch((err) => {
                    toast.warn(err.message)

                })

        }
        // this.props.history("/")
        this.setState({ loading: false })
    }
    render() {

        return (
            <div>

                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={hermoso}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>{this.props.lang === 'en' ? 'Forgot Password?' : 'Mot de passe oublié?'}</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p style={{ color: "grey", fontSize: "15px" }}>{this.props.lang === 'en' ? "Enter the email address you used when you joined and we will send you instructions to reset your password" : "Entrez l'adresse e-mail que vous avez utilisée lors de votre inscription et nous vous enverrons des instructions pour réinitialiser votre mot de passe"}</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>{this.props.lang === 'en' ? 'Email' : 'E-mail'}</p>
                                </div>
                                <input value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className='register-input' placeholder={this.props.lang === 'en' ? 'Email Address' : 'Adresse e-mail'}></input>



                                <button onClick={() => this.loginHandler()} loading={this.state.loading} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>{this.props.lang === 'en' ? 'Send reset instructions' : 'Envoyer les instructions de réinitialisation'}</p>
                                    }
                                </button>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%", marginBottom: "2%" }}>
                                    <div className="line-grey">
                                    </div>
                                    <div><p style={{ color: "grey", margin: "0", fontSize: "20px" }}>{this.props.lang === 'en' ? 'or' : 'ou'}</p></div>
                                    <div className="line-grey">
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <p style={{ color: "black", }}>{this.props.lang === 'en' ? 'Return to' : 'Retourner à'} </p>
                                    </div>
                                    <div >
                                        <p onClick={() => this.props.history("/")} style={{ color: "#2A5555", fontWeight: 500, cursor: "pointer", marginLeft: "5px" }}>{this.props.lang === 'en' ? 'Sign in' : "S'identifier"}</p>
                                    </div>
                                </div>
                                {/* <div style={{ width: "100%", marginTop: "8%" }}>
                                    <div style={{ width: "100%" }} >
                                        <p className="selectlanguage">{this.props.lang === 'en' ? 'Select Language' : 'Choisir la langue'}</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%" }}>
                                        <div onClick={() => this.props._changeLang("nor")} style={{ marginRight: "2%", color: this.props.lang !== "en" ? " #0063F5" : "black" }}>
                                            <div style={{ marginBottom: "3%", display: "flex", justifyContent: "center", alignItems: "center", width: "40px", height: "40px" }}>
                                                <img width="100%" height="100%" src={england}></img>
                                            </div>
                                            <div>
                                                <p>French</p>
                                            </div>
                                        </div>
                                        <div onClick={() => this.props._changeLang("en")} style={{ marginLeft: "2%", color: this.props.lang === "en" ? " #0063F5" : "black" }}>
                                            <div style={{ marginBottom: "3%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <img src={america}></img>
                                            </div>
                                            <div>
                                                <p style={{ marginLeft: "10%" }}>English</p>
                                            </div>
                                        </div>
                                    </div>

                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="design-bottom">


                    </div> */}

                </div>

            </div >
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(ForgotPassword));
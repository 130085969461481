import { LOGOUT, SET_USER, CHANGE_LANG, SET_UPDATE_PROFILE } from "./actionType";



const setUsers = (payload) => ({
    type: SET_USER,
    payload,
});

const logout = () => ({
    type: LOGOUT,

});
const changeLang = (payload) => ({
    type: CHANGE_LANG,
    payload,

});
const set_updateProfile = (payload) => (
    {
        type: SET_UPDATE_PROFILE,
        payload
    }
);

export default {
    setUsers,
    logout,
    changeLang,
    set_updateProfile
};

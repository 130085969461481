import React from 'react';
import "../css/DataDropDown.css";
const DataDropDown = ({ value, options, onChange }) => {
    return (
        <label>
            <select style={{ border: "none", width: "70%", borderRadius: "10px", height: "3.5rem", background: "#ffffff", color: "#AFAFAF", }} value={value} onChange={onChange}>
                {options.map((option) => (
                    <option style={{ color: "black" }} value={option.value} className="option0"  >{option.label}</option>

                ))}
            </select>
        </label>
    )
}

export default DataDropDown;

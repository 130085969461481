import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Sidebar from './Componenets/Sidebar';
import Dashboard from './Screens/Dashboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from './Componenets/withRouter';
import { connect } from 'react-redux';
import Loginmain from './Screens/loginmain';
import SignUpmain from './Screens/SignUpmain';
import AsignRoles from './Screens/AsignRoles';
import CustomerSupport from './Screens/CustomerSupport';
import EmergencyDetails from './Screens/EmergencyDetails';
import Users from './Screens/Users';
import ForgotPassword from './Screens/ForgotPassword';
import NewPassword from './Screens/NewPassword';
import ResetPassword from './Screens/ResetPassword';
import PrivacyPolicy from './Screens/PrivacyPolicy';

const App = (props) => {

  return (
    <div>

      <div style={{ display: "flex" }}>
        {
          props?.user && window.location.pathname != "/privacy" ?

            <div style={{ width: "20%", minHeight: "100vh" }}>
              <Sidebar />
            </div> :
            null
        }


        <div style={{ width: "100%" }}>
          <React.Fragment>
            <ToastContainer />
            <Routes>

              {
                !props.user ?
                  <React.Fragment>
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/" element={<Loginmain />} />
                    <Route path="/signup" element={<SignUpmain />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="/newpassword" element={<NewPassword />} />
                    <Route path="/resetpassword" element={<ResetPassword />} />

                  </React.Fragment>
                  :
                  <React.Fragment>

                    <Route path="/" element={<Dashboard />} />
                    <Route path="/asignroles" element={<AsignRoles />} />
                    <Route path="/customerSupport" element={<CustomerSupport />} />

                    <Route path="/emergencydetails" element={<EmergencyDetails />} />
                    <Route path="/users" element={<Users />} />



                  </React.Fragment>
              }


            </Routes>
          </React.Fragment>
        </div>
      </div>
    </div>
  )
}
const mapStatetoProps = (state) => {
  return {
    user: state.user,

    logged: state.logged
  }
}
export default connect(mapStatetoProps, null)(withRouter(App));

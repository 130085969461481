import React, { Component } from "react";
import "../css/Login.css"
import triaden from "../assets/triaden.png"
import england from "../assets/england.png"
import america from "../assets/america.png"
import { withRouter } from "../Componenets/withRouter";
import { style } from "@mui/system";
import { login } from "../Path/Path";
import { toast } from "react-toastify";
import action from "../store/action";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import store from "../store"
import { CircularProgress } from '@mui/material';

class loginmain extends Component {
    state = {
        email: "",
        password: "",
        loading: false,

    }
    handler = async () => {
        const body = {
      
            "email": this.state.email,
            "password": this.state.password,
    
 
 
     
        }
        if (this.state.email === "") {
            toast.warn("Enter Email")
        }
        else if (this.state.password === "") {
            toast.warn("Enter password")
        }

        else {
            await this.setState({ loading: true })
            console.log(body);
            await login(body)
                .then((res) => {
                    console.log(res, "ressssssssssssssss")
                    if (res?.success) {
                        let info = jwt_decode(res?.token)
                        console.log('info: ', info);
                        store.dispatch(action.setUsers(info?.payload))
                        toast.success(res?.message)
                        this.setState({ loading: false })
                    }
                    else {
                        toast.warn(res?.message)
                        this.setState({ loading: false })
                    }
                }).catch((err) => {
                    console.log('err: ', err);
                    toast.warn(err?.message)
                    this.setState({ loading: false })
                })
        }

        // this.props.history("/")
    }
    render() {
        return (
            <div>

                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={triaden}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>{this.props.lang === "en" ? "Sign in" : "S'identifier"}</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>{this.props.lang === "en" ? "Email" : "E-mail"}</p>
                                </div>
                                <input value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className='register-input' placeholder={this.props.lang === "en" ? 'Email Address' : "Adresse e-mail"}></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>{this.props.lang === "en" ? "Password" : "Mot de passe"}</p>
                                </div>
                                <input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className='register-input' placeholder={this.props.lang === "en" ? 'Password' : "Mot de passe"}></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "4%", marginTop: "2%" }}>
                                        <div style={{ display: "flex" }}>
                                            {/* <div>
                                                <input type="checkbox"></input>
                                            </div>
                                            <div style={{ width: "100px" }}>
                                                <p style={{ color: "grey", marginLeft: "6%", marginTop: "0", marginBottom: "4px" }}>{this.props.lang === "en" ? "keep me logged in" : "Rester connecté"}</p>
                                            </div> */}

                                        </div>
                                        <div>
                                            <p onClick={() => this.props.history("/forgotpassword")} style={{ color: "#0063F5", cursor: "pointer" }}>{this.props.lang === "en" ? "Forgot Password?" : "Mot de passe oublié?"}</p>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => this.handler()} loading={this.state.loading} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>{this.props.lang === "en" ? "Sign in" : "S'identifier"}</p>
                                    }
                                </button>
                              
                               
                            </div>
                        </div>
                    </div>

                    {/* <div className="design-bottom">


                    </div> */}

                </div>

            </div >
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(loginmain));
import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import "../css/Modal.css";
import DropDown from './DropDown';
import "../css/DropDown.css";
import { TiDelete } from "react-icons/ti";
import { withRouter } from './withRouter';
import { connect } from 'react-redux';
import action from '../store/action';

const CustomerSupportModal = ({ lang, open, onClick, status, getStatus, onUpdate }) => {
    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <div className='close-btn' onClick={onClick}><TiDelete /></div>
            <DialogTitle id="dialog-title">
                <h2>{lang === "en" ? "Edit Status" : "Modifier le statut"}</h2>
                <div className='line'></div>
            </DialogTitle>
            <DialogContent>

                <div className='drop'>
                    {/* <label style={{ fontSize: "14px", color: "#272B41" }}>{lang === "en" ? "Status" : "Statut"}</label> */}
                    <DropDown
                        options={[
                            { label: lang === "en" ? "Select Status" : "Sélectionnez le statut", value: 'Select Status' },
                            { label: lang === "en" ? "Pending" : "En attente", value: false },
                            { label: lang === "en" ? "Resolved" : "Résolue", value: true },

                        ]}
                        onChange={e => getStatus(e.target.value)}
                        value={status}
                    />
                </div>
            </DialogContent>
            <div className='but-flex'>
                <div className='cancel'>
                    <button onClick={onClick}>{lang === "en" ? "Cancel" : "Annuler"}</button>
                </div>
                <div className='save-changes'>
                    <button onClick={()=>onUpdate()}>{lang === "en" ? "Save" : "sauvegarder"}</button>
                </div>
            </div>
        </Dialog>
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _changeLang: payload => dispatch(action.changeLang(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(CustomerSupportModal));
